import { API_LOCAL_BASE_URL } from "config";
import ReactPixel from "react-facebook-pixel";

export const fetchCart = async (
  cartToken,
  shopUrl,
  silentLoginToken,
  referral,
  rc
) => {
  try {
    const apiUrl = `${API_LOCAL_BASE_URL}api/Checkout/FetchCart?cartToken=${cartToken}&shopUrl=${shopUrl}&silentLoginToken=${silentLoginToken}&referral=${referral}&rc=${rc}`;

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(response);
    }
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const validateAddress = async (shopUrl, streetAddress, city, zip) => {
  try {
    const apiUrl = `${API_LOCAL_BASE_URL}api/Checkout/ValidateAddress?shopUrl=${shopUrl}&zip=${zip}&city=${city}&address=${streetAddress}`;

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorMessage = `Network response was not ok. Status: ${response.status}, ${response.statusText}`;
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const recalculateCart = async (checkout) => {
  const apiUrl = `${API_LOCAL_BASE_URL}api/Checkout/CalculateCart`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CartToken: checkout.cartToken,
        ShopUrl: checkout.shopUrl,
        CustomerToken: checkout.customerToken,
        SelectedShippingMethodId: checkout.cart.shipMethodID,
        DiscountRcID: checkout.cart.discountRcID,
        ReferralId: checkout.cart.referralId,
        ShippingAddress: shippingWrapper(checkout),
        Password: checkout.shipping.password,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error(
        "Failed to recalculate cart[shipment]:",
        response.statusText
      );
    }
  } catch (error) {
    console.error("Error:", error.message);
  }
};

export const confirmOrder = async (checkout) => {
  const apiUrl = `${API_LOCAL_BASE_URL}api/Checkout/ConfirmOrder`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        checkoutDetails: checkout,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to confirm order:", response);
    }
  } catch (error) {
    console.error("Error:", error.message);
    throw error;
  }
};

export const submitOrder = async (checkout, tokenization) => {
  const apiUrl = `${API_LOCAL_BASE_URL}api/Checkout/SubmitOrder`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(checkoutDetails(checkout, tokenization)),
    });
    ReactPixel.track("Purchase", {
      currency: checkout?.cart?.currency,
      value: checkout?.cart?.orderTotal,
    });
    return response;
  } catch (error) {
    console.error("Error:", error.message);
    throw error;
  }
};

export const validateAccount = async (account) => {
  const apiUrl = `${API_LOCAL_BASE_URL}api/Checkout/ValidateAccount`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ account }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to validate address:", response);
    }
  } catch (error) {
    console.error("Error on validate address:", error.message);
    throw error;
  }
};

export const checkEmailExists = async (email, shopUrl) => {
  try {
    const response = await fetch(
      `${API_LOCAL_BASE_URL}api/Checkout/CheckEmailExists?email=${email}&shopUrl=${shopUrl}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const checkPhoneExists = async (phone, shopUrl) => {
  try {
    const response = await fetch(
      `${API_LOCAL_BASE_URL}api/Checkout/CheckPhoneExists?phone=${phone}&shopUrl=${shopUrl}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const IsWebaliasAvailable = async (webAlias, shopUrl) => {
  try {
    const response = await fetch(
      `${API_LOCAL_BASE_URL}api/Checkout/IsWebaliasAvailable?webAlias=${webAlias}&shopUrl=${shopUrl}`
    );
    const data = await response.json();

    if (data.isAvailable) {
      return {
        valid: "Username is available.",
        error: "",
        isLoading: false,
      };
    } else {
      return {
        valid: "",
        error: "Username is not available.",
        isLoading: false,
      };
    }
  } catch (error) {
    return {
      valid: "",
      error: "Error checking Username availability.",
      isLoading: false,
    };
  }
};

export const shippingWrapper = (checkout) => {
  let shipping = checkout.shipping.streetAddress
    ? checkout.shipping
    : checkout.newAddress;
  let wrapper = {};
  wrapper.city = shipping.city;
  wrapper.country = shipping.country;
  wrapper.state = shipping.state;
  wrapper.phone = shipping.phone;
  wrapper.email = shipping.email;
  wrapper.streetAddress = shipping.streetAddress;
  wrapper.address1 = shipping.streetAddress;
  wrapper.zip = shipping.zip;
  wrapper.SelectedShippingMethodID = "9";
  return wrapper;
};

export const checkoutDetails = (checkout, tokenization) => {
  const isAmbassadorOrAffiliate =
    checkout.enrollType === "ambassador" || checkout.enrollType === "affiliate";
  let customerType = 4; // Default to 4 for customer

  if (isAmbassadorOrAffiliate) {
    customerType = checkout.enrollType === "ambassador" ? 3 : 6; // 3: Ambassador, 6: Affiliate
  }

  let wrapper = {};

  wrapper.CartToken = checkout.cartToken;
  wrapper.ShopUrl = checkout.shopUrl;
  wrapper.CurrentStep = checkout.currentStep;
  wrapper.CustomerToken = checkout.customerToken;
  wrapper.ApplyPoints = checkout.applyPoints;
  wrapper.PriceTypeID = checkout.cart.priceTypeID;
  wrapper.DiscountRcID = checkout.cart.discountRcID;
  wrapper.FirstName = checkout.shopifyCustomer.firstName;
  wrapper.LastName = checkout.shopifyCustomer.lastName;
  wrapper.EnrollerId = checkout.cart.referralId;
  wrapper.Email = isAmbassadorOrAffiliate
    ? checkout.shipping.email
    : checkout.shipping.loginEmail;
  wrapper.Webalias = checkout.customer.webAlias;
  wrapper.Password = checkout.shipping.password;
  wrapper.UserName = isAmbassadorOrAffiliate
    ? checkout.shipping.username
    : checkout.shipping.loginEmail;
  wrapper.Phone = checkout.shipping.phone;
  wrapper.CustomerType = customerType;
  if (checkout.enrollType === "ambassador") {
    wrapper.BirthDate = checkout.shipping.birthDate;
  }
  //wrapper.Password = checkout.customer.password;
  wrapper.PrimaryPhone = checkout.customer.phone;
  wrapper.ShippingAddressSameAsMain = checkout.sameShippingAddress;
  wrapper.BillingAddressSameAsMain = checkout.billingAddress.sameAsShipping;
  wrapper.PaymentMethod = checkout.creditcard;
  wrapper.PaymentMethod.Token = tokenization.token;
  wrapper.PaymentMethod.PaymentMethodName = tokenization.cardType;
  wrapper.PaymentMethod.ExpirationMonth = checkout.creditcard.ExpirationMonth;
  wrapper.PaymentMethod.ExpirationYear = checkout.creditcard.ExpirationYear;
  wrapper.MainAddress = {
    /* ... */
  };
  wrapper.ShippingAddress = checkout.shipping;
  wrapper.ShippingAddress.address1 = checkout.shipping.streetAddress;
  if (checkout.billingAddress.sameAsShipping) {
    wrapper.BillingAddress = checkout.shipping;
  } else {
    wrapper.BillingAddress = checkout.billingAddress;
  }
  wrapper.BillingAddress.SameAsShipping =
    checkout.billingAddress.sameAsShipping;
  //wrapper.SelectedShippingMethodID = checkout.cart.shipMethodID;
  wrapper.SelectedShippingMethodID = "9";
  if (checkout.subscription.startDate !== "") {
    wrapper.Subscription = checkout.subscription;
  }

  return wrapper;
};
