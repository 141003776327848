export const emailExistsMessage = (value) => (
  <p>
    Your Email is already in our system but we need you to set up your account
    with our new cart.{" "}
    <a
      href={`https://www.oolalife.com/account/register?emailRegistered=${value}`}
      target="_blank"
      rel="noreferrer"
    >
      Sign Up Here
    </a>
  </p>
);

export const phoneExistsMessage = (
  <p>Your Phone is already in our system, please insert a different one.</p>
);
